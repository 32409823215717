import React, {useState, useEffect} from "react";
import {Button, Container, Tab} from "semantic-ui-react";

export default function TimeMachineAuditSubComponent(props) {
    const [panes, setPanes] = useState([]);

    useEffect(function updatePanes() {
        console.log(props.payloads);
        let updatedMessage = "No message";
        if (props.message) {
            try {
                updatedMessage = JSON.stringify(JSON.parse(atob(props.message)), null, 4);
            } catch (_) {
                updatedMessage = atob(props.message);
            }
        }

        const updatedPanes = [{
            menuItem: "Message",
            render: () => (
                <Container className="masterContainer" style={{alignItems: "stretch", minWidth: "100%"}}>
                    <Button floated="left" disabled={!props.message} onClick={() => copyToClipboard("Message", updatedMessage)}>Copy Message</Button>
                    <br /><br /><br />
                    <pre style={{overflowY: "scroll"}}>{updatedMessage}</pre>
                </Container>
            )
        }];

        if (Array.isArray(props.payloads)) {
            for (const payload of props.payloads) {
                if (payload.hasOwnProperty("type") && payload.hasOwnProperty("payload")) {
                    let stringifiedPayload;
                    try {
                        stringifiedPayload = JSON.stringify(JSON.parse(atob(payload.payload)), null, 4);
                    } catch (e) {
                        console.error(`unexpected error parsing payload: ${payload}`, e);
                        stringifiedPayload = atob(payload.payload);
                    }

                    updatedPanes.push({
                        menuItem: payload.type,
                        render: () => (
                            <Container className="masterContainer" style={{alignItems: "stretch"}}>
                                <Button floated="left" onClick={() => copyToClipboard(payload.type, stringifiedPayload)}>{`Copy ${payload.type} Payload`}</Button>
                                <br /><br /><br />
                                <pre style={{alignItems: "stretch", minWidth: "100%", overflowY: "scroll"}}>{stringifiedPayload}</pre>
                            </Container>
                        )
                    });
                }
            }
        }

        setPanes(updatedPanes);
    }, [props.message, JSON.stringify(props.payloads)]);

    const copyToClipboard = (type, payload) => {
        navigator.clipboard.writeText(payload).then(() => {
            props.toast("Copied", `You have copied the payload with type ${type} to your clipboard.`, "success");
        });
    }

    return (
        <Tab
            fluid
            styled
            defaultActiveIndex={[0]}
            exclusive={false}
            panes={panes}
            menu={{
                vertical: true,
                fluid: true,
                tabular: true
            }}
        />
    );
};