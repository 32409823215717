import React, {useEffect, useMemo, useState} from "react";
import {Accordion, Button, Checkbox, Form, Grid, Icon, List, Select, Message, Container, Confirm} from "semantic-ui-react";
import TimeMachineDataProvider from "../../../Services/TimeMachineDataProvider";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {MaterialReactTable} from "material-react-table";
import TimeMachineAuditSubComponent from "./TimeMachineAuditSubComponent";
import {useHistory, useLocation} from "react-router";

const ACTIONS = ["Ingest", "Delete", "Update", "Replay"];
const INGESTERS = ["LMDB", "DATACITY", "ALEPH", "LET"];
const PAYLOAD_TYPES = ["ALEPH", "DC_DELETED", "DC_SCHED_V2", "DC_SCHED_V2_TM", "DC_TITLES_V2", "DC_TITLES_V2_TM", "DC_TITLES_V3", "DC_TITLES_V3_TM", "INFLOW", "LET_API", "LET_SQS", "LMDB", "TM", "TOWER", "VOD2LIVE"];
const STATUSES = ["COMPLETED", "DUPLICATE", "FAILED", "INGESTING", "NOT_PROCESSED", "REMOVED", "REPLAYING", "REPLAY_COMPLETED", "SKIPPED", "SKIPPED_INFLOW", "WILL_PROCESS", "WILL_RETRY"];

const DATE_DISPLAY_OPTIONS = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric"};
const INITIAL_LOADING_TIME = Date.now();
const ONE_DAY_IN_MS = 86400000;

export default function TimeMachineAuditMasterDetailView(props) {
    const [defaultMetadata, setDefaultMetadata] = useState({});
    const [allInstances, setAllInstances] = useState([]);
    const [actionOptions, setActionOptions] = useState([]);
    const [instanceOptions, setInstanceOptions] = useState([]);
    const [ingesterOptions, setIngesterOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [propEpOptions, setPropEpOptions] = useState([]);
    const [externalIdOptions, setExternalIdOptions] = useState([]);
    const [showIdOptions, setShowIdOptions] = useState([]);
    const [titleIdOptions, setTitleIdOptions] = useState([]);
    const [payloadTypeOptions, setPayloadTypeOptions] = useState([]);
    const [selectedAction, setSelectedAction] = useState("");
    const [selectedInstances, setSelectedInstances] = useState([]);
    const [matchAnyInstances, setMatchAnyInstances] = useState(true);
    const [selectedIngesters, setSelectedIngesters] = useState([]);
    const [matchAnyIngesters, setMatchAnyIngesters] = useState(true);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [matchAnyStatuses, setMatchAnyStatuses] = useState(true);
    const [selectedPropEps, setSelectedPropEps] = useState([]);
    const [matchAnyPropEps, setMatchAnyPropEps] = useState(true);
    const [selectedExternalIds, setSelectedExternalIds] = useState([]);
    const [matchAnyExternalIds, setMatchAnyExternalIds] = useState(true);
    const [selectedShowIds, setSelectedShowIds] = useState([]);
    const [matchAnyShowIds, setMatchAnyShowIds] = useState(true);
    const [selectedTitleIds, setSelectedTitleIds] = useState([]);
    const [matchAnyTitleIds, setMatchAnyTitleIds] = useState(true);
    const [selectedPayloadTypes, setSelectedPayloadTypes] = useState([]);
    const [matchAnyPayloadTypes, setMatchAnyPayloadTypes] = useState(true);
    const [selectedStartTime, setSelectedStartTime] = useState(INITIAL_LOADING_TIME - ONE_DAY_IN_MS);
    const [selectedEndTime, setSelectedEndTime] = useState(INITIAL_LOADING_TIME + ONE_DAY_IN_MS);
    const [receivedAuditData, setReceivedAuditData] = useState([]);
    const [visibleAuditData, setVisibleAuditData] = useState([]);
    const [searching, setSearching] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [allowReplaying, setAllowReplaying] = useState(false);
    const [replaying, setReplaying] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 50,
        pageIndex: 0,
    });
    const [pages, setPages] = useState(-1);
    const [hasMore, setHasMore] = useState(true);
    const history = useHistory();
    const {search} = useLocation();
    const [searchButtonClicked, setSearchButtonClicked] = useState(false);
    const [startSearch, setStartSearch] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    useMemo(function loadFromURLSearchParams() {
        const searchParams = new URLSearchParams(search);
        let doSearch = false;
        if (searchParams.has("action")) {
            setSelectedAction(searchParams.get("action"));
            doSearch = true;
        }

        if (searchParams.has("pageSize") || searchParams.has("page")) {
            const updatedPageSize = searchParams.has("pageSize") ? searchParams.get("pageSize") : 50;
            const updatedPageIndex = searchParams.has("page") ? searchParams.get("page") : 0;
            setPagination({
                pageSize: parseInt(updatedPageSize),
                pageIndex: parseInt(updatedPageIndex),
            });
            doSearch = true;
        }

        if (searchParams.has("instance") || searchParams.has("instances")) {
            let instances;
            if (searchParams.has("instance")) {
                instances = searchParams.get("instance").split(",");
            } else {
                instances = searchParams.get("instances").split(",");
            }

            const currentInstances = new Set();
            for (const instanceOption of instanceOptions) {
                currentInstances.add(instanceOption.value);
            }

            const updatedInstanceOptions = [];
            for (const instance of Array.from(currentInstances)) {
                updatedInstanceOptions.push({key: instance, text: instance, value: instance});
            }

            for (const instance of allInstances) {
                if (!currentInstances.has(instance)) {
                    updatedInstanceOptions.push({key: instance, text: instance, value: instance});
                }
            }

            const updatedMatchAnyInstances = !searchParams.get("excludeInstances");
            setMatchAnyInstances(updatedMatchAnyInstances);
            console.log("TimeMachineAuditMasterDetailView.loadFromURLSearchParams: updated instance options: ", updatedInstanceOptions, "instances: ", instances);
            setInstanceOptions(updatedInstanceOptions);
            setSelectedInstances(instances);
            doSearch = true;
        }

        if (searchParams.has("ingester") || searchParams.has("ingesters")) {
            let ingesters;
            if (searchParams.has("ingester")) {
                ingesters = searchParams.get("ingester").split(",");
            } else {
                ingesters = searchParams.get("ingesters").split(",");
            }

            const updatedIngesterOptions = [];
            for (const ingester of defaultMetadata?.["audit_options"]?.["ingester"] ?? INGESTERS) {
                updatedIngesterOptions.push({key: ingester, text: ingester, value: ingester});
            }
            for (const ingester of ingesters) {
                if (!(defaultMetadata?.["audit_options"]?.["ingester"] ?? INGESTERS).includes(ingester)) {
                    updatedIngesterOptions.push({key: ingester, text: ingester, value: ingester});
                }
            }

            const updatedMatchAnyIngesters = !searchParams.get("excludeIngesters");
            setMatchAnyIngesters(updatedMatchAnyIngesters);
            setIngesterOptions(updatedIngesterOptions);
            setSelectedIngesters(ingesters);
            doSearch = true;
        }

        if (searchParams.has("propEp") || searchParams.has("propEps")) {
            let propEps;
            if (searchParams.has("propEp")) {
                propEps = searchParams.get("propEp").split(",");
            } else {
                propEps = searchParams.get("propEps").split(",");
            }

            const updatedPropEpOptions = [];
            for (const propEp of propEps) {
                updatedPropEpOptions.push({key: propEp, text: propEp, value: propEp});
            }

            const updatedMatchAnyPropEps = !searchParams.get("excludePropEps");
            setMatchAnyPropEps(updatedMatchAnyPropEps);
            setPropEpOptions(updatedPropEpOptions);
            setSelectedPropEps(propEps);
            doSearch = true;
        }

        if (searchParams.has("status") || searchParams.has("statuses")) {
            let statuses;
            if (searchParams.has("status")) {
                statuses = searchParams.get("status").split(",");
            } else {
                statuses = searchParams.get("statuses").split(",");
            }

            const updatedStatusOptions = [];
            for (const status of defaultMetadata?.["audit_options"]?.["status"] ?? STATUSES) {
                updatedStatusOptions.push({key: status, text: status, value: status});
            }
            for (const status of statuses) {
                if (!(defaultMetadata?.["audit_options"]?.["status"] ?? STATUSES).includes(status)) {
                    updatedStatusOptions.push({key: status, text: status, value: status});
                }
            }

            const updatedMatchAnyStatuses = !searchParams.get("excludeStatuses");
            setMatchAnyStatuses(updatedMatchAnyStatuses);
            setStatusOptions(updatedStatusOptions);
            setSelectedStatuses(statuses);
            doSearch = true;
        }

        if (searchParams.has("externalId") || searchParams.has("externalIds")) {
            let externalIds;
            if (searchParams.has("externalId")) {
                externalIds = searchParams.get("externalId").split(",");
            } else {
                externalIds = searchParams.get("externalIds").split(",");
            }

            const updatedExternalIdOptions = [];
            for (const externalId of externalIds) {
                updatedExternalIdOptions.push({key: externalId, text: externalId, value: externalId});
            }

            const updatedMatchAnyExternalIds = !searchParams.get("excludeExternalIds");
            setMatchAnyExternalIds(updatedMatchAnyExternalIds);
            setExternalIdOptions(updatedExternalIdOptions);
            setSelectedExternalIds(externalIds);
            doSearch = true;
        }

        if (searchParams.has("showId") || searchParams.has("showIds")) {
            let showIds;
            if (searchParams.has("showId")) {
                showIds = searchParams.get("showId").split(",");
            } else {
                showIds = searchParams.get("showIds").split(",");
            }
            const updatedShowIdOptions = [];
            for (const showId of showIds) {
                updatedShowIdOptions.push({key: showId, text: showId, value: showId});
            }

            const updatedMatchAnyShowIds = !searchParams.get("excludeShowIds");
            setMatchAnyShowIds(updatedMatchAnyShowIds);
            setShowIdOptions(updatedShowIdOptions);
            setSelectedShowIds(showIds);
            doSearch = true;
        }

        if (searchParams.has("titleId") || searchParams.has("titleIds")) {
            let titleIds;
            if (searchParams.has("titleId")) {
                titleIds = searchParams.get("titleId").split(",");
            } else {
                titleIds = searchParams.get("titleIds").split(",");
            }
            const updatedTitleIdOptions = [];
            for (const titleId of titleIds) {
                updatedTitleIdOptions.push({key: titleId, text: titleId, value: titleId});
            }

            const updatedMatchAnyTitleIds = !searchParams.get("excludeTitleIds");
            setMatchAnyTitleIds(updatedMatchAnyTitleIds);
            setTitleIdOptions(updatedTitleIdOptions);
            setSelectedTitleIds(titleIds);
            doSearch = true;
        }

        if (searchParams.has("payloadType") || searchParams.has("payloadTypes")) {
            let payloadTypes;
            if (searchParams.has("payloadType")) {
                payloadTypes = searchParams.get("payloadType").split(",");
            } else {
                payloadTypes = searchParams.get("payloadTypes").split(",");
            }

            const updatedPayloadTypeOptions = [];
            for (const payloadType of defaultMetadata?.["audit_options"]?.["payload_type"] ?? PAYLOAD_TYPES) {
                updatedPayloadTypeOptions.push({key: payloadType, text: payloadType, value: payloadType});
            }

            for (const payloadType of payloadTypes) {
                if (!(defaultMetadata?.["audit_options"]?.["payload_type"] ?? PAYLOAD_TYPES).includes(payloadType)) {
                    updatedPayloadTypeOptions.push({key: payloadType, text: payloadType, value: payloadType});
                }
            }

            const updatedMatchAnyPayloadTypes = !searchParams.get("excludePayloadTypes");
            setMatchAnyPayloadTypes(updatedMatchAnyPayloadTypes);
            setPayloadTypeOptions(updatedPayloadTypeOptions);
            setSelectedPayloadTypes(payloadTypes);
            doSearch = true;
        }

        if (searchParams.has("startTime")) {
            setSelectedStartTime(parseInt(searchParams.get("startTime")));
            doSearch = true;
        }

        if (searchParams.has("endTime")) {
            setSelectedEndTime(parseInt(searchParams.get("endTime")));
            doSearch = true;
        }

        console.log("TimeMachineAuditMasterDetailView.loadFromURLSearchParams: search params: ", searchParams);

        setAllowReplaying(doSearch);
    }, [JSON.stringify(search)]);

    useEffect(function initialize() {
        TimeMachineDataProvider.init(process.env.TIME_MACHINE_ADMIN_URL);

        TimeMachineDataProvider.getWhitelistedMetadata().then(response => {
            console.log("TimeMachineAuditMasterDetailView.initialize: whitelisted metadata: ", response);
            setDefaultMetadata(response?.["default"] ?? {});
        });

        TimeMachineDataProvider.getInstances().then(response => {
            const updatedAllInstances = new Set();
            if (Array.isArray(response)) {
                response.sort();
                for (const instance of response) {
                    updatedAllInstances.add(instance);
                }
            }

            setAllInstances(Array.from(updatedAllInstances).sort());
        });

        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.delete("startTime");
        urlSearchParams.delete("endTime");

        if (urlSearchParams.has("page") && urlSearchParams.get("page") === "0") {
            urlSearchParams.delete("page");
        }

        if (urlSearchParams.get("pageSize") === "50") {
            urlSearchParams.delete("pageSize");
        }

        console.log("TimeMachineAuditMasterDetailView.initialize: url search param entries length: ", Array.from(urlSearchParams.entries()).length);
        setStartSearch(false);
        if (Array.from(urlSearchParams.entries()).length > 0 && !searching) {
            console.log("TimeMachineAuditMasterDetailView.initialize: url search params: ", Array.from(urlSearchParams.entries()));
            setSearchButtonClicked(true);
            setStartSearch(true);
        }
    }, []);

    useEffect(function updateInstanceOptions() {
        const updatedInstanceOptions = [];
        for (const instance of allInstances) {
            updatedInstanceOptions.push({key: instance, text: instance, value: instance});
        }
        setInstanceOptions(updatedInstanceOptions);
    }, [JSON.stringify(allInstances)]);

    useEffect(function initializeDropdowns() {
        const updatedIngesterOptions = [{key: "default-ingester", text: "", value: ""}];

        for (const ingester of defaultMetadata?.["audit_options"]?.["ingester"] ?? INGESTERS) {
            updatedIngesterOptions.push({key: ingester, text: ingester, value: ingester});

        }

        setIngesterOptions(updatedIngesterOptions);

        const updatedStatusOptions = [{key: "default-status", text: "", value: ""}];
        for (const status of defaultMetadata?.["audit_options"]?.["status"] ?? STATUSES) {
            updatedStatusOptions.push({key: status, text: status, value: status});
        }

        setStatusOptions(updatedStatusOptions);

        const updatedActionOptions = [{key: "default-action", text: "", value: ""}];

        for (const action of defaultMetadata?.["audit_options"]?.["action"] ?? ACTIONS) {
            updatedActionOptions.push({key: action, text: action, value: action});
        }

        setActionOptions(updatedActionOptions);

        const updatedPayloadTypes = [{key: "default-payload-type", text: "", value: ""}];
        for (const payloadType of defaultMetadata?.["audit_options"]?.["payload_type"] ?? PAYLOAD_TYPES) {
            updatedPayloadTypes.push({key: payloadType, text: payloadType, value: payloadType});
        }

        setPayloadTypeOptions(updatedPayloadTypes);
    }, [JSON.stringify(defaultMetadata)]);

    useEffect(function replayCriteriaChanged() {
        if (allowReplaying) {
            setAllowReplaying(false);
        }
    }, [JSON.stringify(selectedIngesters), JSON.stringify(selectedExternalIds), JSON.stringify(selectedTitleIds), JSON.stringify(selectedShowIds), selectedStartTime, selectedEndTime]);

    const getAuditData = async () => {
        if (!isUpdating) {
            setIsUpdating(true);

            const urlSearchParams = new URLSearchParams({
                ...selectedAction && {
                    action: selectedAction
                },
                ...pagination.pageSize && {
                    pageSize: pagination.pageSize
                },
                ...selectedPayloadTypes.length > 0 && {
                    payloadTypes: selectedPayloadTypes
                },
                ...matchAnyPayloadTypes === false && {
                    excludePayloadTypes: true
                },
                ...selectedTitleIds.length > 0 && {
                    titleIds: selectedTitleIds
                },
                ...matchAnyTitleIds === false && {
                    excludeTitleIds: true
                },
                ...selectedExternalIds.length > 0 && {
                    externalIds: selectedExternalIds
                },
                ...selectedPropEps.length > 0 && {
                    propEps: selectedPropEps
                },
                ...matchAnyExternalIds === false && {
                    excludeExternalIds: true
                },
                ...selectedShowIds.length > 0 && {
                    showIds: selectedShowIds
                },
                ...matchAnyShowIds === false && {
                    excludeShowIds: true
                },
                ...selectedInstances.length > 0 && {
                    instances: selectedInstances
                },
                ...matchAnyInstances === false && {
                    excludeInstances: true
                },
                ...selectedIngesters.length > 0 && {
                    ingesters: selectedIngesters
                },
                ...matchAnyIngesters === false && {
                    excludeIngesters: true
                },
                ...selectedStatuses.length > 0 && {
                    statuses: selectedStatuses
                },
                ...matchAnyStatuses === false && {
                    excludeStatuses: true
                },
                ...selectedStartTime !== null && {
                    startTime: typeof selectedStartTime == "number" ? selectedStartTime : Date.parse(selectedStartTime)
                },
                ...selectedEndTime !== null && {
                    endTime: typeof selectedEndTime == "number" ? selectedEndTime : Date.parse(selectedEndTime)
                }
            });

            history.replace({ pathname: location.pathname, search: urlSearchParams.toString() });

            setReceivedAuditData([]);

            if (!isUpdating) {
                const updatedReceivedAuditData = await updateReceivedData([]);
                setReceivedAuditData(updatedReceivedAuditData);
                setVisibleAuditData(updatedReceivedAuditData.slice(0, pagination.pageSize));
            }
            setIsUpdating(false);
        }
    };

    useEffect(function onPageSizeUpdate() {
        const urlSearchParameters = new URLSearchParams(location.search);
        urlSearchParameters.set("pageSize", pagination.pageSize);
        history.replace({ pathname: location.pathname, search: urlSearchParameters.toString() });
        console.log("TimeMachineAuditMasterDetailView.onPageSizeUpdate: location.search: ", location.search);
    }, [pagination.pageSize]);

    useEffect(() => {
        if (pagination.pageIndex < 0) {
            setPagination({
                pageIndex: 0,
                pageSize: pagination.pageSize
            });
        }

        const urlSearchParameters = new URLSearchParams(location.search);
        urlSearchParameters.set("page", pagination.pageIndex);
        history.replace({ pathname: location.pathname, search: urlSearchParameters.toString() });
        console.log("TimeMachineAuditMasterDetailView.onPageIndexUpdate: location.search: ", location.search);
        }, [pagination.pageIndex]);

    useEffect(() => {
        async function onPageUpdates() {
            console.log(`TimeMachineAuditMasterDetailView.onPageUpdates: new pagination: `, pagination);
            let updatedReceivedAuditData = receivedAuditData.slice();
            const firstIndexInPage = pagination.pageIndex * pagination.pageSize;
            const lastIndexInPage = firstIndexInPage + pagination.pageSize;

            if (updatedReceivedAuditData.length <= lastIndexInPage) {
                updatedReceivedAuditData = await updateReceivedData(receivedAuditData);
                setReceivedAuditData(updatedReceivedAuditData);
            }
            const updatedVisibleAuditData = updatedReceivedAuditData.slice(firstIndexInPage, lastIndexInPage);
            console.log(`TimeMachineAuditMasterDetailView.onPageUpdates: received audit data length: ${updatedReceivedAuditData.length}; first index in page: ${firstIndexInPage}; last record on current page: ${lastIndexInPage}; audits: `, updatedVisibleAuditData);

            setVisibleAuditData(updatedVisibleAuditData);
            setIsUpdating(false);
        }

        if (!isUpdating && searchButtonClicked) {
            setIsUpdating(true);
            onPageUpdates().then(() => {
                setIsUpdating(false);
            });
        }
    }, [JSON.stringify(pagination)]);

    async function updateReceivedData(receivedAuditData=[]) {
        console.log("TimeMachineAuditMasterDetailView.updateReceivedData: querying for more data");
        let currentlyHasMore = true;
        const updatedReceivedAuditData = receivedAuditData.slice();
        let firstIndexInPage = pagination.pageIndex * pagination.pageSize;
        let lastIndexInPage = firstIndexInPage + pagination.pageSize;
        let lastIndexInNextPage = lastIndexInPage + pagination.pageSize;
        let remainingAudits = lastIndexInNextPage % pagination.pageSize + pagination.pageSize;

        while (currentlyHasMore === true && updatedReceivedAuditData.length <= lastIndexInNextPage + 1) {
            let totalAudits = updatedReceivedAuditData.length;
            console.log(`TimeMachineAuditMasterDetailView.updateReceivedData: hasMore: ${hasMore}, length of received audit data: ${totalAudits}, last index in page: ${lastIndexInPage}; start index? ${totalAudits}; page size(${typeof pagination.pageSize}): ${pagination.pageSize}; total audits: ${totalAudits} remaining audits? ${remainingAudits}`);
            try {
                const response = await TimeMachineDataProvider.getAudit(selectedInstances, selectedIngesters, selectedStatuses, selectedAction, remainingAudits, selectedPayloadTypes, selectedPropEps, selectedTitleIds, selectedExternalIds, selectedShowIds, selectedStartTime, selectedEndTime, matchAnyInstances, matchAnyIngesters, matchAnyStatuses, matchAnyPayloadTypes, matchAnyPropEps, matchAnyShowIds, matchAnyExternalIds, matchAnyTitleIds, totalAudits);

                console.log(response);
                if (response.hasOwnProperty("error")) {
                    console.error(response.error);
                }

                if (!response.hasOwnProperty("has_more") || response.has_more !== true) {
                    currentlyHasMore = false;
                    setHasMore(false);
                }

                if (response.hasOwnProperty("results")) {
                    for (const result of response.results) {
                        updatedReceivedAuditData.push(result);
                    }

                    firstIndexInPage = pagination.pageIndex * pagination.pageSize;
                    lastIndexInPage = firstIndexInPage + pagination.pageSize;
                    remainingAudits = lastIndexInNextPage % pagination.pageSize + pagination.pageSize;
                }
            } catch (error) {
                props.toast("There was an error getting audit data.", "error");
                console.error(error);
            } finally {
                setSearching(false);
            }
        }

        return updatedReceivedAuditData;
    }

    useEffect(() => {
        const updatedVisibleAuditData = receivedAuditData.slice(pagination.pageIndex * pagination.pageSize, pagination.pageIndex * pagination.pageSize + pagination.pageSize)
        console.log("TimeMachineAuditMasterDetailView.onReceivedAuditDataUpdate: updated visible audit data: ", updatedVisibleAuditData);
        setVisibleAuditData(updatedVisibleAuditData);

        const incrementIfNextPageExists = hasMore ? 1 : 0;
        const totalPagesDownloaded =  receivedAuditData.length / parseInt(pagination.pageSize);
        let pagesAvailable = Math.floor( totalPagesDownloaded + incrementIfNextPageExists);
        const updatedPages = pagesAvailable < 1 ? 1 : pagesAvailable;
        setPages(updatedPages);
        console.log(`TimeMachineAuditMasterDetailView.onReceivedAuditDataUpdate: total audits: ${receivedAuditData.length}; result limit: ${pagination.pageSize}; pages: ${updatedPages}; updated received audit data:`, receivedAuditData);

    }, [JSON.stringify(receivedAuditData)]);

    const clearFilters = () => {
        setSelectedAction("");
        setPagination({
            pageIndex: 0,
            pageSize: 50
        })
        setSelectedStatuses([]);
        setSelectedPropEps([]);
        setSelectedExternalIds([]);
        setSelectedShowIds([]);
        setSelectedTitleIds([]);
        setSelectedInstances([]);
        setSelectedIngesters([]);
        setSelectedPayloadTypes([]);
        setMatchAnyInstances(true);
        setMatchAnyIngesters(true);
        setMatchAnyStatuses(true);
        setMatchAnyPropEps(true);
        setMatchAnyPayloadTypes(true);
        setMatchAnyShowIds(true);
        setMatchAnyTitleIds(true);
        setMatchAnyExternalIds(true);
        setSelectedStartTime(INITIAL_LOADING_TIME - ONE_DAY_IN_MS);
        setSelectedEndTime(INITIAL_LOADING_TIME + ONE_DAY_IN_MS);
    };

    const onClickSearch = () => {
        console.log(`TimeMachineAuditMasterDetailView.onClickSearch: startSearch: ${startSearch}; searching? ${searching}; isUpdating? ${isUpdating}`);
        setStartSearch(true);
    };

    useEffect(function onUpdateStartSearch() {
        console.log(`TimeMachineAuditMasterDetailView.onUpdateStartSearch: startSearch: ${startSearch}; searching? ${searching}; isUpdating? ${isUpdating}`);

        if (startSearch) {
            setStartSearch(false);
            setSearching(true);
            setSearchButtonClicked(true);
            setPagination({
                pageIndex: 0,
                pageSize: pagination.pageSize
            });
            setReceivedAuditData([]);
            setVisibleAuditData([]);
            if (startSearch && !searching && !isUpdating) {
                new Promise(async resolve => {
                    console.log(`TimeMachineAuditMasterDetailView.onUpdateStartSearch: searching: ${searching}; updating: ${isUpdating}; pagination:`, pagination);
                    await getAuditData();
                }).finally(() => {
                    setAllowReplaying(true);
                    setSearching(false);
                    setIsUpdating(false);
                });
            }
        }

    }, [startSearch]);

    const replayClicked = () => {
        setReplaying(true);
        setConfirmOpen(false);
        TimeMachineDataProvider.replay(selectedInstances, selectedIngesters, selectedExternalIds, selectedShowIds, selectedTitleIds, selectedPayloadTypes, selectedPropEps, selectedStatuses, selectedStartTime, selectedEndTime, matchAnyInstances, matchAnyIngesters, matchAnyPayloadTypes, matchAnyShowIds, matchAnyExternalIds, matchAnyTitleIds, matchAnyPropEps, matchAnyStatuses).then(response => {
            console.log("TimeMachineAuditMasterDetailView.replayClicked: response: ", response);

            if (response.hasOwnProperty("error")) {
                console.error("TimeMachineAuditMasterDetailView.replayClicked", response.error);
                props.toast("Error", response.error, "error");
            }

            if (response.hasOwnProperty("results")) {
                setReceivedAuditData(response.results);
            }
        }).finally(() => {
            setReplaying(false);
        });
    };

    const openConfirm = () =>{
        setConfirmOpen(true);
    }

    const closeConfirm = () => {
        setConfirmOpen(false);
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={4} className="masterContainer" style={{maxHeight: "95vh", overflowY: "auto", overflowX: "hidden"}}>
                    <Form widths={16}>
                        <Form.Field>
                            <Button color="blue" onClick={onClickSearch} fluid><Icon name="search" />&nbsp;Search</Button>
                        </Form.Field>
                        <Form.Field>
                            <Form.Field>
                                <Button fluid color="black" onClick={clearFilters}><Icon name="trash" />&nbsp;Clear Filters</Button>
                            </Form.Field>
                        </Form.Field>
                        <Form.Field>
                            <Button fluid color="green" onClick={openConfirm} disabled={!allowReplaying} icon><Icon name={replaying ? "spinner" : "repeat"} />&nbsp;Replay</Button>
                            <Confirm open={confirmOpen} onCancel={closeConfirm} onConfirm={replayClicked} header='Confirm Replay' confirmButton="Replay" content="Are you sure you want to replay?" />
                        </Form.Field>
                        <Form.Field
                            label="Action"
                            control={Select}
                            search
                            options={actionOptions}
                            value={selectedAction}
                            onChange={(event, {value}) => setSelectedAction(value)}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Ingester</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyIngesters === true}
                                                    onClick={() => setMatchAnyIngesters(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyIngesters === false}
                                                    onClick={() => setMatchAnyIngesters(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={ingesterOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedIngesters}
                            onChange={(event, {value}) => setSelectedIngesters(value)}
                            onAddItem={(event, {value}) => setIngesterOptions(showIdOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Instance</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyInstances === true}
                                                    onClick={() => setMatchAnyInstances(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyInstances === false}
                                                    onClick={() => setMatchAnyInstances(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={instanceOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedInstances}
                            onChange={(event, {value}) => setSelectedInstances(value)}
                            onAddItem={(event, {value}) => setInstanceOptions(instanceOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Payload Type</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyPayloadTypes === true}
                                                    onClick={() => setMatchAnyPayloadTypes(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyPayloadTypes === false}
                                                    onClick={() => setMatchAnyPayloadTypes(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={payloadTypeOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedPayloadTypes}
                            onChange={(event, {value}) => setSelectedPayloadTypes(value)}
                            onAddItem={(event, {value}) => setPayloadTypeOptions(payloadTypeOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            control={Select}
                            search
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Show ID</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyShowIds === true}
                                                    onClick={() => setMatchAnyShowIds(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyShowIds === false}
                                                    onClick={() => setMatchAnyShowIds(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            value={selectedShowIds}
                            onChange={(event, {value}) => setSelectedShowIds(value)}
                            options={showIdOptions}
                            allowAdditions
                            multiple
                            onAddItem={(event, {value}) => setShowIdOptions(showIdOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>External ID</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyExternalIds === true}
                                                    onClick={() => setMatchAnyExternalIds(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyExternalIds === false}
                                                    onClick={() => setMatchAnyExternalIds(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            multiple
                            search
                            allowAdditions
                            options={externalIdOptions}
                            value={selectedExternalIds}
                            onChange={(event, {value}) => setSelectedExternalIds(value)}
                            onAddItem={(event, {value}) => setExternalIdOptions(externalIdOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Title ID</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyTitleIds === true}
                                                    onClick={() => setMatchAnyTitleIds(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyTitleIds === false}
                                                    onClick={() => setMatchAnyTitleIds(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            multiple
                            search
                            allowAdditions
                            options={titleIdOptions}
                            value={selectedTitleIds}
                            onChange={(event, {value}) => setSelectedTitleIds(value)}
                            onAddItem={(event, {value}) => setTitleIdOptions(titleIdOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>PropEp</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyPropEps === true}
                                                    onClick={() => setMatchAnyPropEps(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyPropEps === false}
                                                    onClick={() => setMatchAnyPropEps(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            multiple
                            search
                            allowAdditions
                            options={propEpOptions}
                            value={selectedPropEps}
                            onChange={(event, {value}) => setSelectedPropEps(value)}
                            onAddItem={(event, {value}) => setPropEpOptions(propEpOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            control={Select}
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Status</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyStatuses === true}
                                                    onClick={() => setMatchAnyStatuses(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyStatuses === false}
                                                    onClick={() => setMatchAnyStatuses(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            value={selectedStatuses}
                            onChange={(event, {value}) => setSelectedStatuses(value)}
                            onAddItem={(event, {value}) => setStatusOptions(statusOptions.concat({key: value, text: value, value}))}
                            options={statusOptions}
                            multiple
                            search
                            clearable
                        />
                        <Form.Field>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <label>Start Time</label>
                                <DateTimePicker
                                    inputVariant="outlined"
                                    fullWidth
                                    id="start"
                                    value={selectedStartTime}
                                    onChange={setSelectedStartTime}
                                />
                            </MuiPickersUtilsProvider>
                        </Form.Field>
                        <Form.Field width={16}>
                            <label>End Time</label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    inputVariant="outlined"
                                    fullWidth
                                    id="end"
                                    value={selectedEndTime}
                                    onChange={setSelectedEndTime}
                                />
                            </MuiPickersUtilsProvider>
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column width={12} style={{maxHeight: "95vh", overflowY: "auto", overflowX: "hidden"}}>
                {
                    !searchButtonClicked ?
                    <Message color="yellow" icon="info" style={{margin: "2em",width: "auto"}}>
                        <Message.Content>Please enter your search criteria and search to continue.</Message.Content>
                    </Message>:
                <>
                    <Grid.Row>
                        <Container fluid className="masterContainer">
                            <MaterialReactTable
                                columns={[
                                    {header: "Action", accessorKey: "action", size: 64},
                                    {header: "Ingester", accessorKey: "ingester", size: 72},
                                    {
                                        header: "Instance",
                                        accessorKey: "feed_id",
                                        size: 120,
                                        Cell: ({cell}) => {
                                            const value = cell.getValue();
                                            const rowId = cell.row.original._id;
                                            return (
                                                Array.isArray(value) && value.length > 0 ?
                                                    value.length > 1 ?
                                                        <Accordion>
                                                            <Accordion.Panel
                                                                title={activeAccordion === `${rowId}-instances` ? "Multiple Instances" : value[0]}
                                                                index={0}
                                                                content={
                                                                    <Accordion.Content>
                                                                        <List>
                                                                            {value.sort().map((instance, index) => <List.Item key={`${rowId}-${instance}-${index}`}>{instance}</List.Item>)}
                                                                        </List>
                                                                    </Accordion.Content>
                                                                }
                                                                active={activeAccordion === `${rowId}-instances`}
                                                                onTitleClick={
                                                                    (event, data) => {
                                                                        setActiveAccordion(activeAccordion === `${rowId}-instances` ? null : `${rowId}-instances`);
                                                                    }
                                                                }
                                                            />
                                                        </Accordion> : value[0] : value ? value : ""
                                            );
                                        },
                                    },
                                    {
                                        header: "External ID",
                                        accessorKey: "external_id",
                                        Cell: ({cell}) => {
                                            const value = cell.getValue();
                                            const rowId = cell.row.original._id;
                                            return (
                                                Array.isArray(value) && value.length > 0 ?
                                                    value.length > 1 ?
                                                        <Accordion>
                                                            <Accordion.Panel
                                                                title={activeAccordion === `${rowId}-externalIds` ? "Multiple External IDs" : value[0]}
                                                                index={0}
                                                                content={
                                                                    <Accordion.Content>
                                                                        <List>
                                                                            {value.sort().map((externalId, index) => <List.Item key={`${rowId}-${externalId}-${index}`}>{externalId}</List.Item>)}
                                                                        </List>
                                                                    </Accordion.Content>
                                                                }
                                                                active={activeAccordion === `${rowId}-externalIds`}
                                                                onTitleClick={
                                                                    (event, data) => {
                                                                        setActiveAccordion(activeAccordion === `${rowId}-externalIds` ? null : `${rowId}-externalIds`);
                                                                    }
                                                                }
                                                            />
                                                        </Accordion> : value[0] : value ? value : ""
                                            );
                                        },
                                        filterMethod: (filter, rows) => rows[filter.id] && rows[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase())
                                    },
                                    {
                                        header: "Show ID",
                                        accessorKey: "show_id",
                                        size: 100,
                                        Cell: ({cell}) => {
                                            const value = cell.getValue();
                                            const rowId = cell.row.original._id;

                                            return (
                                                Array.isArray(value) ?
                                                    value.length > 1 ?
                                                        <Accordion>
                                                            <Accordion.Panel
                                                                title={activeAccordion === `${rowId}-showIds` ? "Multiple Show IDs" : value[0]}
                                                                index={0}
                                                                content={
                                                                    <Accordion.Content>
                                                                        <List>
                                                                            {value.map((showId, index) => <List.Item key={`${rowId}-${showId}-${index}`}>{showId}</List.Item>)}
                                                                        </List>
                                                                    </Accordion.Content>
                                                                }
                                                                active={activeAccordion === `${rowId}-showIds`}
                                                                onTitleClick={
                                                                    (event, data) => {
                                                                        setActiveAccordion(activeAccordion === `${rowId}-showIds` ? null : `${rowId}-showIds`);
                                                                    }
                                                                }
                                                            />
                                                        </Accordion> : value[0] : value ? value : ""
                                            );
                                        },
                                        filterMethod: (filter, rows) => rows[filter.id] && rows[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase())
                                    },
                                    {
                                        header: "Title ID",
                                        accessorKey: "title_id",
                                        size: 100,
                                        Cell: ({cell}) => {
                                            const value = cell.getValue();
                                            const rowId = cell.row.original._id;
                                            return (
                                                Array.isArray(value) && value.length > 0 ?
                                                    value.length > 1 ?
                                                        <Accordion>
                                                            <Accordion.Panel
                                                                title={activeAccordion === `${rowId}-titleIds` ? "Multiple Title IDs" : value[0]}
                                                                index={0}
                                                                content={
                                                                    <Accordion.Content>
                                                                        <List>
                                                                            {value.sort().map((titleId, index) => <List.Item key={`${rowId}-${titleId}-${index}`}>{titleId}</List.Item>)}
                                                                        </List>
                                                                    </Accordion.Content>
                                                                }
                                                                active={activeAccordion === `${rowId}-titleIds`}
                                                                onTitleClick={
                                                                    (event, data) => {
                                                                        setActiveAccordion(activeAccordion === `${rowId}-titleIds` ? null : `${rowId}-titleIds`);
                                                                    }
                                                                }
                                                            />
                                                        </Accordion> : value[0] : value ? value : ""
                                            )
                                        },
                                        filterMethod: (filter, rows) => rows[filter.id] && rows[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase())
                                    },
                                    {
                                        header: "PropEp",
                                        accessorKey: "prop_ep",
                                        size: 100,
                                        Cell: ({cell}) => {
                                            const value = cell.getValue();
                                            const rowId = cell.row.original._id;
                                            return (
                                                Array.isArray(value) && value.length > 0 ?
                                                    value.length > 1 ?
                                                        <Accordion>
                                                            <Accordion.Panel
                                                                title={activeAccordion === `${rowId}-propEps` ? "Multiple PropEps" : value[0]}
                                                                index={0}
                                                                content={
                                                                    <Accordion.Content>
                                                                        <List>
                                                                            {value.sort().map((propEp, index) => <List.Item key={`${rowId}-${propEp}-${index}`}>{propEp}</List.Item>)}
                                                                        </List>
                                                                    </Accordion.Content>
                                                                }
                                                                active={activeAccordion === `${rowId}-propEps`}
                                                                onTitleClick={
                                                                    (event, data) => {
                                                                        setActiveAccordion(activeAccordion === `${rowId}-propEps` ? null : `${rowId}-propEps`);
                                                                    }
                                                                }
                                                            />
                                                        </Accordion> : value[0] : value ? value : ""
                                            )
                                        },
                                        filterMethod: (filter, rows) => rows[filter.id] && rows[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase())
                                    },
                                    {header: "Status", accessorKey: "status", size: 139},
                                    {
                                        header: "Last Updated",
                                        accessorKey: "lastUpdated",
                                        filterable: false,
                                        defaultSort:"desc",
                                        Cell: ({cell}) => {
                                            return <span>{new Date(cell.getValue() * 1000).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</span>;
                                        }
                                    }
                                ]}
                                enableColumnResizing
                                data={visibleAuditData}
                                filterFns="includesString"
                                layoutMode="grid"
                                state={
                                    {
                                        density: "compact",
                                        isLoading: searching || isUpdating,
                                        showColumnFilters: true,
                                        pagination
                                    }
                                }
                                initialState={
                                    {
                                        sorting: [
                                            {id: "lastUpdated", desc: true}
                                        ]
                                    }
                                }
                                manualPagination
                                rowCount={pages * pagination.pageSize}
                                enableStickyHeader
                                onPaginationChange={setPagination}
                                enableTopToolbar={false}
                                enableColumnFilters
                                enableColumnActions={false}
                                muiExpandButtonProps={
                                    ({ row }) => ({
                                        sx: {
                                            transform: row.getIsExpanded() ? "rotate(180deg)" : "rotate(-90deg)",
                                            transition: "transform 0.2s",
                                        },
                                    })
                                }
                                renderDetailPanel={
                                    ({row}) =>
                                        <TimeMachineAuditSubComponent
                                            id={row.original._id}
                                            message={row.original.message}
                                            context={row.original.context}
                                            payloads={row.original.payloads}
                                            status={row.original.status}
                                            toast={props.toast}
                                        />
                                }
                                muiTableContainerProps={
                                    {
                                        sx: {
                                            maxHeight:"80vh"
                                        }
                                    }
                                }
                                muiTableBodyRowProps={
                                    rowInfo => {
                                        if (!rowInfo.isDetailPanel) {
                                            return {
                                                sx: {
                                                    backgroundColor: rowInfo.staticRowIndex % 2 === 0 ? "#f5f5f5" : "",
                                                }
                                            }
                                        } else {
                                            return {
                                                sx: {
                                                    width: "100%"
                                                }
                                            }
                                        }
                                    }
                                }
                                paginationDisplayMode="pages"
                                positionPagination="both"
                            />
                        </Container>
                    </Grid.Row>
                </>
                }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};
